import React from "react";
import { getEntityList,getProfile } from "./ApiService";
import GlobalReducer from "./GlobalReducer";

let initialState = {entity:{}};

export const GlobalContext = React.createContext(initialState);
const Global = ({ children }) => {
  const [state, dispatch] = React.useReducer(GlobalReducer, initialState);

  React.useEffect(() => {
    getAllEntityList();
    getAllProfile();
  }, [])

  const getAllProfile = async () => {
    var response;
    // let body = formik.values
    const Token = await localStorage.getItem("token");
    if(Token){
      try {
        response = await getProfile(Token);
      
        if (response) {
          if (response.data) {
            // console.log((response.data, 'sfffffffffffffffffffffffffffffffff'));
            await dispatch({type: "PROFILE",value: response.data, token: Token});
          }
        }
      } catch (e) {
        console.log(e);
      }
    }else{
      window.location.pathname = "/";
    }
  };

  const getAllEntityList = async () => {
    var response;
    // let body = formik.values
    try {
      response = await getEntityList();
      if (response) {
        if (response.data) {
          console.log(response.data,"11111111111111111111111111111111111111111111111111111");
          // setEntitySchema(response.data);
          await dispatch({ type: 'ENTITY', value: response.data[0] });
          console.log(response.data,"11111111111111111111111111111111111111111111111111111");
        }


      }

    } catch (e) {
      console.log(e);
    }
  };

  return (
    <GlobalContext.Provider value={{ State: state, StateDispatch: dispatch }}>
      {children}
    </GlobalContext.Provider>
  );
};

export default Global;      
